import React from "react"
import { Link } from "gatsby"
import gql from "graphql-tag"
import { useQuery } from "react-apollo"
import { createLocalLink } from "../utils"
import { Nav } from "rsuite"
import { push as Menu } from "react-burger-menu"
import { Dropdown, Sidenav } from "rsuite"
import ExternalLink from "./ExternalLink"
import styled from "styled-components"

import "../styles/global.css"

import FacebookIcon from "../../static/icons/facebook.svg"

const NavLink = props => <Nav.Item componentClass={Link} {...props} />
const NavLinkEpisode = props => (
  <Dropdown.Item componentClass={Link} {...props} />
)
const MENU_QUERY = gql`
  fragment MenuFields on MenuItem {
    id
    label
    url
    target
    connectedObject {
      __typename
    }
  }
  query GET_MENU_ITEMS {
    menuItems(where: { location: EXPANDED }) {
      nodes {
        ...MenuFields
        childItems {
          nodes {
            ...MenuFields
          }
        }
      }
    }
  }
`

const SOCIAL = [
  {
    icon: FacebookIcon,
    link:
      "https://www.facebook.com/Les-Bonnes-Nouvelles-dAlimata-100163781378310",
  },
]

const renderLink = (menuItem, index) =>
  menuItem.connectedObject.__typename === "MenuItem" ? (
    menuItem.label.toLowerCase().includes("episode ") ? (
      <NavLinkEpisode key={index} to={`/${createLocalLink(menuItem.url)}`}>
        {menuItem.label}
      </NavLinkEpisode>
    ) : (
      <NavLink key={index} to={`${createLocalLink(menuItem.url)}`}>
        {menuItem.label}
      </NavLink>
    )
  ) : createLocalLink(menuItem.url) ? (
    <NavLink key={index} to={`${createLocalLink(menuItem.url)}`}>
      {menuItem.label}
    </NavLink>
  ) : (
    menuItem.label
  )

const renderLinkEpisode = (menuItem, index) =>
  menuItem.connectedObject.__typename === "MenuItem" ? (
    <NavLinkEpisode key={index} to={`${createLocalLink(menuItem.url)}`}>
      {menuItem.label}
    </NavLinkEpisode>
  ) : createLocalLink(menuItem.url) ? (
    <NavLinkEpisode key={index} to={`${createLocalLink(menuItem.url)}`}>
      {menuItem.label}
    </NavLinkEpisode>
  ) : (
    menuItem.label
  )

const renderMenuItem = (menuItem, index, episodes) => {
  if (menuItem.label.toLowerCase().includes("episode ") === false) {
    return renderLink(menuItem, index)
  }
  if (menuItem.label.toLowerCase().includes("episode ") && episodes) {
    return renderLinkEpisode(menuItem, index)
  }
}

const CustomMenu = () => {
  const { loading, error, data } = useQuery(MENU_QUERY)
  if (loading) return <p></p>
  if (error) return <p>Something wrong happened with the menu!</p>

  if (data.menuItems) {
    return (
      <div id="outer-container" className={"texte"}>
        <Menu
          right
          disableAutoFocus
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        >
          <Sidenav className="menu">
            <Sidenav.Body>
              <Nav>
                {data.menuItems.nodes.map((menuItem, i) => {
                  if (
                    menuItem.label &&
                    menuItem.label.toLowerCase().includes("accueil")
                  ) {
                    return renderMenuItem(menuItem, i, false)
                  }
                  return null
                })}
                <Dropdown title="La Série">
                  {data.menuItems.nodes.map((menuItem, i) => {
                    if (menuItem.url.includes("episode")) {
                      return renderMenuItem(menuItem, i, true)
                    }
                    return null
                  })}
                </Dropdown>
                {data.menuItems.nodes.map((menuItem, i) => {
                  if (menuItem.url.includes("conseils")) {
                    return renderMenuItem(menuItem, i, false)
                  }
                  return null
                })}
                {data.menuItems.nodes.map((menuItem, i) => {
                  if (menuItem.url.includes("acteurs")) {
                    return renderMenuItem(menuItem, i, false)
                  }
                  return null
                })}
                {data.menuItems.nodes.map((menuItem, i) => {
                  if (menuItem.url.includes("pdf")) {
                    return (
                      <li class="rs-nav-item">
                        <a
                          href={menuItem.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          role="button"
                          tabindex="0"
                          class="rs-nav-item-content"
                        >
                          {menuItem.label}
                          <span class="rs-ripple-pond">
                            <span class="rs-ripple"></span>
                          </span>
                        </a>
                      </li>
                    )
                  }
                  return null
                })}
                {data.menuItems.nodes.map((menuItem, i) => {
                  if (menuItem.url.includes("contact")) {
                    return renderMenuItem(menuItem, i, false)
                  }
                  return null
                })}
                <Nav.Item href="/contact">Contact</Nav.Item>
                <SocialIcons>
                  {SOCIAL.map(({ icon, link }) => (
                    <ExternalLink key={link} href={link}>
                      <img src={icon} alt="link" />
                    </ExternalLink>
                  ))}
                </SocialIcons>
              </Nav>
            </Sidenav.Body>
          </Sidenav>
        </Menu>
      </div>
    )
  } else {
    return null
  }
}

export default CustomMenu

const SocialIcons = styled.div`
  background-color: #f9c414;
  display: flex;
  justify-content: center;

  img {
    margin: 0 8px;
    width: 20px;
    height: 20px;
    margin-top: 1rem;
  }
  a {
    background-color: #f9c414;
  }
`
