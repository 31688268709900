import React from "react"
import { Footer, FlexboxGrid, Col } from "rsuite"
import ExternalLink from "./ExternalLink"
import styled from "styled-components"

import FacebookIcon from "../../static/icons/facebook.svg"

import "rsuite/dist/styles/rsuite-default.css"

const SOCIAL = [
  {
    icon: FacebookIcon,
    link:
      "https://www.facebook.com/Les-Bonnes-Nouvelles-dAlimata-100163781378310",
  },
]

const FooterSection = () => (
  <Footer>
    <FlexboxGrid justify="center" className="wrap-footer">
      <FlexboxGrid.Item
        componentClass={Col}
        colspan={24}
        md={20}
        xs={24}
        className="footer-wrap"
      >
        <FlexboxGrid justify="space-between">
          <FlexboxGrid.Item
            componentClass={Col}
            colspan={24}
            md={8}
            xs={24}
            className="left"
          >
            <p className="copyright-footer">
              {new Date().getFullYear()}{" "}
              <a
                className="copyright-text-link"
                href="http://www.afriqueavenir.fr/"
              >
                AFRIQUE AVENIR
              </a>
            </p>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            componentClass={Col}
            colspan={24}
            md={8}
            xs={24}
            className="right"
          >
            <SocialIcons>
              {SOCIAL.map(({ icon, link }) => (
                <ExternalLink key={link} href={link}>
                  <img src={icon} alt="link" />
                </ExternalLink>
              ))}
            </SocialIcons>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  </Footer>
)
export default FooterSection

const SocialIcons = styled.div`
  display: flex;
  img {
    margin: 0 8px;
    width: 20px;
    height: 20px;
  }
`
