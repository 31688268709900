import React from "react"
import logo from "../../static/petit-logo.png"
import "rsuite/dist/styles/rsuite-default.css"
import "../styles/global.css"

const TopHeader = () => (
  <div className="top-header">
    <a href="/" className="logo">
      <img src={logo} className="petit-logo" alt="logo" />
    </a>
    <p className="headline_menu_title">PAR <span>AFRIQUE AVENIR</span></p>
    {/* <p className="headline_menu">MENU</p> */}
  </div>
)
export default TopHeader
